import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Helmet from "../component/common/Helmet";
import { FiAperture , FiBattery , FiCamera ,FiChevronUp, FiFilm, FiHardDrive, FiPrinter, FiSpeaker, FiTruck, FiImage } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";


const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--31',
        category: '',
        title: 'MAYOREOS',
        description: 'Compra de Todo',
        buttonText: 'Contactanos',
        buttonLink: 'mailto:info@mayoreos.net'
    }
]

const ServiceListOne = [
    {
        icon: <FiAperture />,
        title: 'Accesorios Fotograficos',
        description: ''
    },
    {
        icon: <FiBattery />,
        title: 'Baterias',
        description: ''
    },
    {
        icon: <FiCamera />,
        title: 'Camaras',
        description: ''
    },
    {
        icon: <FiHardDrive />,
        title: 'Memorias',
        description: ''
    },
    {
        icon: <FiPrinter />,
        title: 'Impresoras',
        description: ''
    },
    {
        icon: <FiSpeaker />,
        title: 'Electronicos',
        description: ''
    },
    {
        icon: <FiFilm />,
        title: 'Peliculas Fotograficas',
        description: ''
    },
    {
        icon: <FiTruck />,
        title: 'Lubricantes & Grasas de Maquinaria',
        description: ''
    },
    {
        icon: <FiImage />,
        title: 'Papel y Quimico Fotografico',
        description: ''
    }
]

const starndardService = [
    {
        image: '',
        title: 'COSTA SUR',
        description: '',
    },
    {
        image: '',
        title: 'CAPITAL',
        description: '',
    },
    {
        image: '',
        title: 'OCCIDENTE',
        description: '',
    },
    {
        image: '',
        title: 'ORIENTE Y PETEN',
        description: '',
    }
   
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        return(
            <Fragment> 
                <Helmet pageTitle="MAYOREOS" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Service Area */}
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">Rutas</h2>
                                    <br/>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="content">
                                                    <h3>{value.title}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}

                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Acerca de Nosotros</h2>
                                        <p>El grupo de empresas de venta al por mayor, Distrubuidora Mayoreos del Norte, S.A. y Mayoreos Consolidados, S.A. surge de un grupo de empresas   de venta al detalle con mas de 30 años en el mercado Guatemalteco para satisfacer la necesidad de Centroamerica en distribucion de productos de fotografia, electronica, energia, computacion, y automovilismo. </p>
                                        <p>Mayoreos del norte se enfoca en  hacer rutas a travez de toda Guatemala con las cuales nuestros vendedores visitan a clientes y les entregan el producto inmediatamente sin costo de flete.</p>
                                        <p>Mayoreos consolidados  se enfoca en proporcionale al mercado Centroamericano con productos necesarios e innovadores a precios justos, enviandoselos convenientemente sus bodegas en Guatemala. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Producto de Calidad</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="20" />
                        </div>
                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <br/>
                                    <br/>
                                    <h4>Mayoreos</h4>
                                    <h5>4ta Calle 3-07 Zona 10, Ciudad de Guatemala, Guatemala 01010 Guatemala</h5>
                                    <h5>+502 2259-0811</h5>
                                </div>

                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                        <p>MARCAS QUE DISTRIBUIMOS:</p>
                        <br/>
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;