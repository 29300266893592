import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="GAZPROMNEFT"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="GENERGY"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="CANON"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="KODAK"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="MITSUBISHI"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="SILIBRAIN"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="LUSZOL"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="WANDA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="SINFONIA"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;